<template>
  <div class="add-new-customer">
    <title-bar
      submitBtn
      submitBtnTitle="Update employee"
      title="Save employee"
      @submitPressed="submitPressed"
    />
    <employee-form  entity="Employee" redirect="/employees" v-if="employee" :requiredRole="true" :userObject="employee" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import EmployeeForm from './components/EmployeeForm.vue'
import router from '@/router'

export default {
  components: {
    TitleBar,
    EmployeeForm
  },
  data() {
    return {
      action: null,
      employee: null
    }
  },
  created() {
    this.loadEmployee()
  },
  methods: {
    loadEmployee() {
      const { id } = router.currentRoute.params
      this.$Users.getResource({ id }).then(response => {
        this.employee = response.data
      })
    },
    submitPressed() {
      this.action = 'update'
    }
  },
}
</script>
